<template>
  <section class="invoice-preview-wrapper">



    <b-row
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
      <vue-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        filename="hee hee"
        :pdf-quality="3"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
    >
    <section slot="pdf-content">        
      <b-card
          no-body
          class="invoice-preview-card"
          ref = "sow"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <logo />
                  <h3 class="text-primary invoice-logo">
                    冰山數據
                  </h3>
                </div>
                <p class="card-text mb-25">
                  甲方：{{ projectData.company_name }}
                </p>
                <p class="card-text">
                  乙方：玉案（北京）软件有限公司
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  项目任务书
                </h4>
                  <p class="card-text mb-25">
                  项目名称：{{ projectData.project_name }}
                  </p>
                  <p class="card-text mb-25">
                  项目编号：{{ projectData.project_id }}
                  </p>
                  <p class="card-text">
                  框架合同编号：{{ projectData.contract_iceberg_id }}
                  </p>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
              <div>
                  <h6 class="mb-2">
                    甲方联系人信息
                  </h6>
                  <table>
                    <tbody>
                      <tr v-for="cpm in customerPmData">
                        <td class="pr-1" >
                          项目负责人:{{ cpm.last_name + cpm.first_name }} 
                          <template v-if="cpm.email">
                            {{ "<" + cpm.email + ">" }}
                          </template>
                        </td>
                      </tr>

                      <tr v-for="cbm in customerBmData">
                        <td class="pr-1" >
                          商务负责人:{{ cbm.last_name + cbm.first_name }} 
                          <template v-if="cbm.email">
                              {{ "<" + cbm.email+ ">"  }}
                          </template>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">
                    乙方联系人信息
                  </h6>
                  <table>
                    <tbody>
                      <tr v-for="pm in pmData">
                        <td class="pr-1" >
                          项目负责人:{{ pm.user_name + '<' + pm.user_email + '>'}} 
                        </td>
                      </tr>

                      <tr v-for="bm in bmData">
                        <td class="pr-1" >
                          商务负责人:{{ bm.user_name + '<' + bm.user_email + '>'}} 
                        </td>
                      </tr>
                     
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <hr class="invoice-spacing">

          <b-card-body class="invoice-padding pb-0">
            <b-row class="invoice-spacing">
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
              <h6 class="mb-2">
            项目详情
          </h6>
          <table>
            <tbody>
              <tr>
                <td class="pr-1">
                  项目启动日期：
                </td>
                <td>{{ dateDefault }}</td>
              </tr>
              <tr>
                <td class="pr-1">
                  项目交付日期：
                </td>
                <td>{{ dueDate }}</td>
              </tr>
              <tr>
                <td class="pr-1">
                  数据量：
                </td>
                <td>{{ dataVolume }}</td>
              </tr>
            </tbody>
          </table>
              </b-col>

              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          项目描述:
                        </td>
                        <td>{{ projectDescription }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-card-body>
                <h6 class="mb-2 text-center">
                  准确率标准
                </h6>
              <b-table-lite
            :bordered="true"
            :striped="true"
            responsive
            :items="rateData"
            :fields="['元素', '准确率（%）', '备注']"
          >
            <template #cell(item)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data }}
              </b-card-text>
              <b-card-text class="text-nowrap">
                {{  }}
              </b-card-text>
            </template>
          </b-table-lite>
        </b-card-body>
            </b-row>

          
          </b-card-body>
          <hr class="invoice-spacing">
          <b-card-body class="invoice-padding pb-0">
          <h6 class="mb-2">
            报价单
          </h6>
          <b-table-lite
            :bordered="true"
            :striped="true"
            responsive
            :items="priceData"
            :fields="['元素单位', '单价（元）', '备注']"
          >
            <template #cell(item)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data }}
              </b-card-text>
              <b-card-text class="text-nowrap">
                {{  }}
              </b-card-text>
            </template>
          </b-table-lite>

          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text v-if="otherDescription.length" class="mb-0">
                  <span class="font-weight-bold">其他说明:</span>
                  <span class="ml-75">{{ otherDescription }}</span>
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  
                
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      含税:
                    </p>
                    <p class="invoice-total-amount">
                      1%
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      币种:
                    </p>
                    <p class="invoice-total-amount">
                      ￥人民币元
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          </b-card-body>
          <hr class="invoice-spacing">

          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">注意： </span>
            <span>甲乙双方应遵守商业保密义务，除双方企业内部相关人员外，请勿向任何第三方泄露该文档，感谢理解！</span>
          </b-card-body>
        </b-card>
        </section>
      </vue-html2pdf>

      </b-col>

      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

<!--           <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="primary"
            class="mb-75"
            block
            @click="downloadQuationPdf"
          >
            发起用章审批
        </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="printSow"
          >
            打印
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="sendEmail"
          >
            发送启动邮件
          </b-button> -->

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="saveSow"
            :disabled="isSaving"
          >
            保存
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    
    <b-row class="invoice-preview" id="guideline-preview">
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
      <b-card title="附件" ref="guideline" v-if="guidelineName">
          <a href="#"  @click="downloadGuideline()">{{ guidelineName }}</a>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="editing-area">
      <b-col cols="12" xl="9" md="8">
        <b-card title="编辑">
          <b-card-body>
            <b-row>
              <b-col>
                <b-form-group
                  label="项目启动日期"
                  label-for="startDate"
                >
                <flat-pickr
                  v-model="dateDefault"
                  class="form-control"
                />
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group
                  label="项目交付日期"
                  label-for="dueDate"
                >
                <flat-pickr
                  v-model="dueDate"
                  class="form-control"
                />
                </b-form-group>
              </b-col>
              
              <b-col>
                <b-form-group
                  label="数据量"
                  label-for="dataVolume"
                >
                  <b-form-input
                    v-model="dataVolume"
                    id="dataVolume"
                  />
                </b-form-group>
              </b-col>
            </b-row>

           
           
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>


  </section>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,BFormGroup,BFormTextarea,BFormInput,BCardTitle
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import invoiceStoreModule from '../invoiceStoreModule'
import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'
import flatPickr from 'vue-flatpickr-component'
import pdf from 'vue-pdf'
import printJS from 'print-js'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BCardTitle,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    flatPickr,
    Logo,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    InvoiceSidebarAddPayment,
    InvoiceSidebarSendInvoice,
    pdf,
    printJS,
    VueHtml2pdf
  },
  data(){
    return{
      isSaving:false,
      bmName: JSON.parse(localStorage.getItem('userData')).fullName,
      bmEmail: JSON.parse(localStorage.getItem('userData')).email,
      dateDefault: null,
      dueDate: null,
      dataVolume: null,
      projectDescription: "详见附件",
      otherDescription: '',
      priceData:[],
      rateData:[],
      projectData:{},
      customerBmData: [],
      customerPmData:[],
      bmData:[],
      pmData:[],
      guidelineId: "",
      guidelineUrl: "",
      currentPage: 1,
      totalPages: 0,
      guidelineName: null,
    }
  },
  methods: {
    saveSow(){
      this.isSaving = true
      var icebergTeamInStr = []
      this.pmData.forEach(pm =>{
        icebergTeamInStr.push("项目负责人：" + pm.user_name + "<" + pm.user_email + ">")
      })
      this.bmData.forEach(bm =>{
        icebergTeamInStr.push("商务负责人：" + bm.user_name + "<" + bm.user_email + ">")
      })
      var customerTeamInStr = []
      this.customerPmData.forEach(pm =>{
        customerTeamInStr.push("项目负责人：" + pm.last_name + pm.first_name + "<" + pm.email + ">")
      })
      this.customerBmData.forEach(bm =>{
        customerTeamInStr.push("商务负责人：" + bm.last_name + bm.first_name + "<" + bm.email + ">")
      })
      var newSowData = {
        "cusCompanyName": this.projectData.company_name,
        "cusCompanyId": this.projectData.company_id,
        "projectName": this.projectData.project_name,
        "projectId": this.$route.params.id,
        "contractIcebergId": this.projectData.contract_iceberg_id,
        "projectDescription": this.projectDescription,
        "startDate": this.dateDefault,
        "dueDate": this.dueDate,
        "dataVolume": this.dataVolume,
        "priceData": this.priceData,
        "rateData": this.rateData,
        "icebergTeam":icebergTeamInStr,
        "customerTeam":customerTeamInStr,
        "guidelineId":this.guidelineId,
        "guidelineName":this.guidelineName,
      }
      this.$axios.post("/collab/pm/gen_sow",newSowData).then(resp =>{
        if(resp.data.status == "ok"){
          this.$router.push({name: 'collab-pm-view', params: {id: this.$route.params.id}})
        }else{
          this.isSaving = false
        }
      })
    },
    getPriceData(){
      this.$axios.get('/collab/pm/get_price_tables/' + this.$route.params.id).then(res => {
        let table = (res.data.data.tables.newest_table.price_table)
        table.forEach(row => {
          this.priceData.push({
            "元素单位": row.element,
            "单价（元）": row.price,
            "备注": row.note,
          })
        })
        let rateTable = (res.data.data.tables.newest_table.rate_table)
        rateTable.forEach(row => {
          this.rateData.push({
            "元素": row.element,
            "准确率（%）": row.rate,
            "备注": row.note,
          })
        })
        ;
      })
    },
    getContactData(contactId,role){
      this.$axios.get('/crm/contacts/system/get_contact/' + contactId).then((resp)=>{
        let contactInfo = resp.data.data.contact
        if(role == "customer_bm"){
          this.customerBmData.push(contactInfo)
        }else{
          this.customerPmData.push(contactInfo)
        }
      })
    },
    getUserData(userId,role){
      this.$axios.get('/manage/users/get_user/'+ userId).then((resp) =>{
        if(role == "pm"){
          this.pmData.push(resp.data.data.user)
        }else{
          this.bmData.push(resp.data.data.user)
        }
      })
    },
    getProjectData(){
      this.$axios.get('/collab/pm/get_project/' + this.$route.params.id).then(res => {
        this.projectData = res.data.data.project
        const cusFields = ['customer_pm','customer_bm']
        cusFields.forEach((role) =>{
          (this.projectData[role]).forEach((contactId)=>{
            this.getContactData(contactId,role)
          })
        })
        var userFields = ['bm','pm']
        userFields.forEach((role)=>{
          (this.projectData[role].forEach((userId)=>{
            this.getUserData(userId,role)
          }))
        })
      })
    },
    getUsers(){
      this.$axios.get('/manage/users/get_users').then(res => {
        this.users = res.data.data.users
        let loginUserId = JSON.parse(localStorage.getItem('userData')).userName
        this.users.forEach(user => {
          if(user.id == loginUserId){
            //this.bmName = user
            return true
          }
        })
      })
    },
    getGuideline(){
      this.$axios.get('/collab/pm/get_guidelines/' + this.$route.params.id).then(res => {
        var newestDes = res.data.data.guidelines[0].description
        if(newestDes) this.projectDescription = newestDes

        this.guidelineId = res.data.data.guidelines[0].guideline_id
        this.guidelineName = res.data.data.guidelines[0].guideline_name
      })
    },
    downloadGuideline(fileName){
        this.$axios.get("/collab/pm/download_guideline/" + this.guidelineId).then(res=>{
          const url = this.$backend + '/download_by_token/' + res.data.data.token
          const link = document.createElement('a')
          link.setAttribute('href', url)
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
    },
    numPages(num){
      this.totalPages = num
    }
  },
  created(){
    this.getGuideline()
    this.getPriceData()
    this.getProjectData()
    let date = new Date()
    this.dateDefault = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
  },
  setup() {
    const invoiceData = ref(null)
    const paymentDetails = ref({})

    // Invoice Description
    // ? Your real data will contain this information
    const invoiceDescription = [
      {
        taskTitle: 'Native App Development',
        taskDescription: 'Developed a full stack native app using React Native, Bootstrap & Python',
        rate: '$60.00',
        hours: '30',
        total: '$1,800.00',
      },
      {
        taskTitle: 'UI Kit Design',
        taskDescription: 'Designed a UI kit for native app using Sketch, Figma & Adobe XD',
        rate: '$60.00',
        hours: '20',
        total: '$1200.00',
      },
    ]

    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-invoice/fetchInvoice', { id: router.currentRoute.params.id })
      .then(response => {
        invoiceData.value = response.data.invoice
        paymentDetails.value = response.data.paymentDetails
      })
      .catch(error => {
        if (error.response.status === 404) {
          invoiceData.value = undefined
        }
      })

    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceData,
      paymentDetails,
      invoiceDescription,
      printInvoice,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">

@media print {
  @page {margin:0;size: A4}

  html, body {
     border: 1px solid white;
     height: 99%;
     page-break-after: avoid !important;
     page-break-before: avoid !important;
  }
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  
  .editing-area {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
